
import { utils } from '../utils';


const publicPages = ['/', '/index', '/error']; //public pages which do not need authentation
const excludedRoutes = []; //all pages which do not need authentation
const roleAbilities = {
	"master account": [
		'/', 'home/list', 'account/list', 'account/edit',
		'applicants/list', 'applicants/tertiary_certificate_verify_status',  'applicants/view', 'applicants/edit', 'applicants/importdata',
		'administrators/list', 'administrators/view', 'administrators/edit',
		'caps_institutions_list/list', 'caps_institutions_list/is_accredited', 'caps_institutions_list/view', 'caps_institutions_list/edit',
		'caps_programmes_list/list', 'caps_programmes_list/view', 'caps_programmes_list/edit',
		'caps_institution_programmes/list', 'caps_institution_programmes/delete',
		'minimum_entry_requirements/list', 'minimum_entry_requirements/view',
	],

	"credential evaluation": [
		'/', 'home/list', 'account/list', 'account/edit',
		'applicants/list', 'applicants/tertiary_certificate_verify_status', 'applicants/view', 'applicants/edit', 'applicants/importdata',
	],

	"accreditation": [
		'/', 'home/list', 'account/list', 'account/edit',
		'caps_institutions_list/list', 'caps_institutions_list/is_accredited', 'caps_institutions_list/view', 'caps_institutions_list/edit',
		'caps_programmes_list/list', 'caps_programmes_list/view', 'caps_programmes_list/edit',
		'caps_institution_programmes/list', 'caps_institution_programmes/delete',
		'minimum_entry_requirements/list', 'minimum_entry_requirements/view',
	]
};

export default async ({ app, store, router }) => {
	let user = null;
	let userRoleNames = [];
	let userPages = [];
	let isLoggedIn = false;
	user = store.state.auth.user;
	userRoleNames = store.state.auth.userRoles;
	userPages = store.state.auth.userPages;
	if (user) { //token is still valid
		app.config.globalProperties.$User = user;
		app.config.globalProperties.$UserPages = userPages;
		app.config.globalProperties.$UserRoleNames = userRoleNames;
		app.config.globalProperties.$UserName = user.username;
		app.config.globalProperties.$UserID = user.administrator_id;
		app.config.globalProperties.$UserEmail = user.email;
		app.config.globalProperties.$UserTelephone = null;
		app.config.globalProperties.$UserPhoto = user.photo;
		isLoggedIn = true;
	}
	app.config.globalProperties.$isLoggedIn = isLoggedIn;

	const canView = function (path) {
		let routePath = utils.getRoutePath(path);
		let userRole = utils.lower(user.user_type);
		let userRoleAbilities = roleAbilities[userRole] || [];
		if (userRoleAbilities.includes(routePath)) {
			return true;
		}
		return false;
	}

	// app.config.globalProperties.$check = { isAccreditation, isMaster, isCredentialEvaluation };
	app.config.globalProperties.$can = { view: canView };
	router.beforeEach((to, from, next) => {
		user = store.state.auth.user;

		let path = to.path;

		let pagePath = utils.getPagePath(path);
		let routePath = utils.getRoutePath(path);

		let authRequired = true;

		//check if page is excluded from authentication
		if (publicPages.includes(pagePath) || excludedRoutes.includes(routePath)) {
			authRequired = false;
		}

		//authenticate user
		if (authRequired) {
			if (!user) {
				return next({ path: '/', query: { nexturl: to.fullPath } });
			}

			//authorize user
			if (!canView(path)) {
				return next({ path: "/error/forbidden" });
			}
		}

		if (user && to.name == "index") {
			//already logged in, show home when try to access index page
			return next({ path: "/home" });
		}

		//navigate to redirect url if available
		if (to.name == "home" && to.query.nexturl) {
			return next({ path: to.query.nexturl });
		}

		//continue to specified route
		next();
	});

}
