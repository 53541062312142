
import { createRouter, createWebHashHistory } from 'vue-router';

export default async ({ store }) => {
	let routes = [
		//Dashboard routes

		//applicants routes
		{
			path: '/applicants',
			alias: '/applicants/:fieldName/:fieldValue',
			name: 'applicantslist',
			component: () => import('./pages/applicants/list.vue'),
			props: true
		},

		//accredited_institution_programmes routes
		{
			path: '/accredited_institution_programmes',
			alias: '/accredited_institution_programmes/:fieldName/:fieldValue',
			name: 'accredited_institution_programmeslist',
			component: () => import('./pages/accredited_institution_programmes/list.vue'),
			props: true
		},



		//administrators routes
		{
			path: '/administrators',
			alias: '/administrators/:fieldName/:fieldValue',
			name: 'administratorslist',
			component: () => import('./pages/administrators/list.vue'),
			props: true
		},


		{
			path: '/administrators/view/:id',
			name: 'administratorsview',
			component: () => import('./pages/administrators/view.vue'),
			props: true
		},

		{
			path: '/account/edit',
			name: 'administratorsaccountedit',
			component: () => import('./pages/account/accountedit.vue'),
			props: true
		},

		{
			path: '/account',
			name: 'administratorsaccountview',
			component: () => import('./pages/account/accountview.vue'),
			props: true
		},

		{
			path: '/administrators/add',
			name: 'administratorsadd',
			component: () => import('./pages/administrators/add.vue'),
			props: true
		},

		{
			path: '/administrators/edit/:id',
			name: 'administratorsedit',
			component: () => import('./pages/administrators/edit.vue'),
			props: true
		},



		//applicants routes
		{
			path: '/applicants',
			alias: '/applicants/:fieldName/:fieldValue',
			name: 'applicantslist',
			component: () => import('./pages/applicants/list.vue'),
			props: true
		},


		{
			path: '/applicants/view/:id',
			name: 'applicantsview',
			component: () => import('./pages/applicants/view.vue'),
			props: true
		},

		{
			path: '/applicants/subview/:id',
			name: 'applicantssubview',
			component: () => import('./pages/applicants/subview.vue'),
			props: true
		},


		//caps_institution_programmes routes
		{
			path: '/caps_institution_programmes',
			alias: '/caps_institution_programmes/:fieldName/:fieldValue',
			name: 'caps_institution_programmeslist',
			component: () => import('./pages/caps_institution_programmes/list.vue'),
			props: true
		},


		{
			path: '/caps_institution_programmes/add',
			name: 'caps_institution_programmesadd',
			component: () => import('./pages/caps_institution_programmes/add.vue'),
			props: true
		},

		{
			path: '/caps_institution_programmes/edit/:id',
			name: 'caps_institution_programmesedit',
			component: () => import('./pages/caps_institution_programmes/edit.vue'),
			props: true
		},


		//caps_institutions_list routes
		{
			path: '/caps_institutions_list',
			alias: '/caps_institutions_list/:fieldName/:fieldValue',
			name: 'caps_institutions_listlist',
			component: () => import('./pages/caps_institutions_list/list.vue'),
			props: true
		},


		{
			path: '/caps_institutions_list/view/:id',
			name: 'caps_institutions_listview',
			component: () => import('./pages/caps_institutions_list/view.vue'),
			props: true
		},

		{
			path: '/caps_institutions_list/add',
			name: 'caps_institutions_listadd',
			component: () => import('./pages/caps_institutions_list/add.vue'),
			props: true
		},

		{
			path: '/caps_institutions_list/edit/:id',
			name: 'caps_institutions_listedit',
			component: () => import('./pages/caps_institutions_list/edit.vue'),
			props: true
		},

		{
			path: '/caps_institutions_list/viewmore/:id',
			name: 'caps_institutions_listviewmore',
			component: () => import('./pages/caps_institutions_list/viewmore.vue'),
			props: true
		},


		//caps_programmes_list routes
		{
			path: '/caps_programmes_list',
			alias: '/caps_programmes_list/:fieldName/:fieldValue',
			name: 'caps_programmes_listlist',
			component: () => import('./pages/caps_programmes_list/list.vue'),
			props: true
		},


		{
			path: '/caps_programmes_list/view/:id',
			name: 'caps_programmes_listview',
			component: () => import('./pages/caps_programmes_list/view.vue'),
			props: true
		},

		{
			path: '/caps_programmes_list/add',
			name: 'caps_programmes_listadd',
			component: () => import('./pages/caps_programmes_list/add.vue'),
			props: true
		},

		{
			path: '/caps_programmes_list/edit/:id',
			name: 'caps_programmes_listedit',
			component: () => import('./pages/caps_programmes_list/edit.vue'),
			props: true
		},


		//minimum_entry_requirements routes
		{
			path: '/minimum_entry_requirements',
			alias: '/minimum_entry_requirements/:fieldName/:fieldValue',
			name: 'minimum_entry_requirementslist',
			component: () => import('./pages/minimum_entry_requirements/list.vue'),
			props: true
		},


		{
			path: '/minimum_entry_requirements/view/:id',
			name: 'minimum_entry_requirementsview',
			component: () => import('./pages/minimum_entry_requirements/view.vue'),
			props: true
		},

		{
			path: '/minimum_entry_requirements/add',
			name: 'minimum_entry_requirementsadd',
			component: () => import('./pages/minimum_entry_requirements/add.vue'),
			props: true
		},

		{
			path: '/minimum_entry_requirements/edit/:id',
			name: 'minimum_entry_requirementsedit',
			component: () => import('./pages/minimum_entry_requirements/edit.vue'),
			props: true
		},


		//Password reset routes
		{
			path: '/index/forgotpassword',
			name: 'forgotpassword',
			component: () => import('./pages/index/forgotpassword.vue'),
			props: true
		},
		{
			path: '/index/resetpassword',
			name: 'resetpassword',
			component: () => import('./pages/index/resetpassword.vue'),
			props: true
		},
		{
			path: '/index/resetpassword_completed',
			name: 'resetpassword_completed',
			component: () => import('./pages/index/resetpassword_completed.vue'),
			props: true
		},



		{
			path: '/index/accountcreated',
			name: 'accountcreated',
			component: () => import('./pages/index/accountcreated.vue'),
			props: true
		},
		{
			path: '/index/accountinactive',
			name: 'accountinactive',
			component: () => import('./pages/index/accountinactive.vue'),
			props: true
		},
		{
			path: '/index/accountpending',
			name: 'accountpending',
			component: () => import('./pages/index/accountpending.vue'),
			props: true
		},
		{
			path: '/index/accountblocked',
			name: 'accountblocked',
			component: () => import('./pages/index/accountblocked.vue'),
			props: true
		},

		//Otp verify code routes
		{
			path: '/index/verifyotp',
			name: 'verifyotp',
			component: () => import('./pages/index/verifyotp.vue'),
			props: true
		},


		{
			path: '/error/forbidden',
			name: 'forbidden',
			component: () => import('./pages/errors/forbidden.vue'),
			props: true
		},
		{
			path: '/error/notfound',
			name: 'notfound',
			component: () => import('./pages/errors/pagenotfound.vue'),
			props: true
		},
		{
			path: '/:catchAll(.*)',
			component: () => import('./pages/errors/pagenotfound.vue')
		}
	];


	let loginToken = store.getters["auth/getLoginToken"];
	if (loginToken) {	//Token is available, user might still be logged in
		try {
			//fetch user data for the first time and saves in the vuex store
			await store.dispatch("auth/getUserData");
		}
		catch (e) {
			store.dispatch("auth/logout"); //token must have expired
		}
	}


	let user = store.state.auth.user;
	if (user) {
		routes.push({
			path: '/',
			alias: '/home',
			name: 'home',
			component: () => import(`./pages/home/home.vue`),
			props: true
		});
	}
	else {
		routes.push({
			path: '/',
			alias: '/index',
			name: 'index',
			component: () => import('./pages/index/index.vue'),
			props: true
		});
	}


	const router = createRouter({
		history: createWebHashHistory(),
		routes,
	});

	router.beforeEach(function (to, from, next) {
		window.scrollTo(0, 0);
		next();
	});

	return router;
}
