import { createStore } from "vuex"
import { pageComponents } from "./page_components.js"
import appStore from "./app_store.js";
import { auth } from "./auth.js"
import { accredited_institution_programmes } from "./accredited_institution_programmes.js"
import { administrators } from "./administrators.js"
import { applicants } from "./applicants.js"
import { caps_institution_programmes } from "./caps_institution_programmes.js"
import { caps_institutions_list } from "./caps_institutions_list.js"
import { caps_programmes_list } from "./caps_programmes_list.js"
import { minimum_entry_requirements } from "./minimum_entry_requirements.js"

const { state, getters, mutations, actions } = appStore;

const store = createStore({
	state,
	getters,
	mutations,
	actions,
	modules: {
		pageComponents,
		auth,
		accredited_institution_programmes,
		administrators,
		applicants,
		caps_institution_programmes,
		caps_institutions_list,
		caps_programmes_list,
		minimum_entry_requirements,
	},
	// enable strict mode (adds overhead!)
	// for dev mode only
	//strict: process.env.DEV
});
export default store;