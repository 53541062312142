
export const AppMenus = {

  navbarTopRightItems: [],
  navbarTopLeftItems: [],
  navbarSideLeftItems: [
    {
      "to": "/home",
      "label": "Home",
      "icon": "pi pi-home text-primary",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/applicants",
      "label": "Credential Evaluation",
      "icon": "pi pi-star text-primary",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/caps_institutions_list",
      "label": "Institutions List",
      "icon": "pi pi-building text-primary",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/caps_programmes_list",
      "label": "Programmes List",
      "icon": "pi pi-book text-primary",
      "iconcolor": "",
      "target": "",

    },
    // {
    //   "to": "/caps_institution_programmes",
    //   "label": "Institution Programmes",
    //   "icon": "pi pi-minus text-primary",
    //   "iconcolor": "",
    //   "target": "",

    // },
    {
      "to": "/minimum_entry_requirements",
      "label": "Minimum Entry Requirements",
      "icon": "pi pi-check text-primary",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/administrators",
      "label": "User Accounts",
      "icon": "pi pi-users text-primary",
      "iconcolor": "",
      "target": "",
    },
  ],
  caps_mall_statusItems: [
    { value: "Opened", label: "Opened" },
    { value: "Closed", label: "Closed" }
  ],
  yes_or_noItems: [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" }
  ],
  approval_statusItems: [
    { value: "Approved", label: "Approved" },
    { value: "Declined", label: "Declined" }
  ],
  account_typeItems: [
    { value: "Master Account", label: "Master Account" },
    { value: "Credential Evaluation", label: "Credential Evaluation Officer" },
    { value: "Accreditation", label: "Accreditation Officer" },
  ],
  account_statusItems: [{ "label": "Active", "value": "Active" }, { "label": "Inactive", "value": "Inactive" }, { "label": "Blocked", "value": "Blocked" }],

  exportFormats: {
    print: {
      label: 'Print',
      icon: 'pi pi-print',
      type: 'print',
      ext: '',
    },
    pdf: {
      label: 'Pdf',

      icon: 'pi pi-file-pdf',
      type: 'pdf',
      ext: 'pdf',
    },
    excel: {
      label: 'Excel',
      icon: 'pi pi-file-excel',
      type: 'excel',
      ext: 'xlsx',
    },
    csv: {
      label: 'Csv',
      icon: 'pi pi-table',
      type: 'csv',
      ext: 'csv',
    },
  },

}